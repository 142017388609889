<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-6">
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Báo cáo số lượng ví</h6>
              </template>
              <b-table-simple class="table-bordered" responsive="true" hover>
                <b-thead class="thead-default">
                  <b-tr>
                    <b-th class="align-middle"></b-th>
                    <b-th class="align-middle">Tổng số Ví điện tử</b-th>
                    <b-th class="align-middle">Tổng Số Ví điện tử đã liên kết Ngân hàng</b-th>
                    <b-th class="align-middle">Tổng số Ví điện tử chưa xác thực</b-th>
                    <b-th class="align-middle">Tổng số Ví điện tử đang bị khóa</b-th>
                    <b-th class="align-middle">Tổng số Ví điện tử đang hoạt động (có active trong 90 ngày gần đây)</b-th>
                    <b-th class="align-middle">Tổng số dư Ví điện tử</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Khách hàng cá nhân (không bao gồm ĐVCNTT)</b-td>
                    <b-td>{{ numberFormat(this.item.total_users) }}</b-td>
                    <b-td>{{ numberFormat(this.item.totalLinkBank) }}</b-td>
                    <b-td>{{ numberFormat(this.item.total_not_kyc) }}</b-td>
                    <b-td>{{ numberFormat(this.item.total_is_block) }}</b-td>
                    <b-td>{{ numberFormat(this.item.active_90days) }}</b-td>
                    <b-td>{{ numberFormat(Math.round(this.item.totalBalance)) }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import RepositoryFactory from "../../../../core/repositories/repositoryFactory";

Vue.component("multiselect", Multiselect);

const BiRepository = RepositoryFactory.get("Bi");
export default {
  mixins: [Common],
  data() {
    return {
      filter: {},
      item: {},
      dateFrom: this.getFirstDayOfMonth(),
      dateTo: this.getCurrentDay(),
    };
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Báo cáo số lượng ví" },
    ]);
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$bus.$emit("show-loading", true);
      BiRepository.getReportAccount().then((response) => {
        if (response.data.error_code) {
          this.notifyAlert("warn", response.data.message);
          this.$bus.$emit("show-loading", false);
          return;
        }

        this.item = response.data.data;
        this.$bus.$emit("show-loading", false);
      })
      .catch(() => {
        this.$bus.$emit("show-loading", false);
        alert("Có lỗi xảy ra");
      });
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
input[type="text"],
input[type="date"],
select {
  min-height: 43px;
}
</style>
